import React, { useEffect, useState } from 'react';
import Wrapper from '../components/Wrapper.js';
import '../css/stats.css';
import { services } from '../sevices/service.js';

function Info() {
  const [postsPerDay, setPostsPerDay] = useState([]);

  useEffect(() => {
    const fetchPostsPerDay = async () => {
      try {
        const data = await services.getPostsPerDay(); // This assumes you'll add `getPostsPerDay` in the services file.
        setPostsPerDay(data);
      } catch (error) {
        console.error('Error fetching posts per day:', error);
      }
    };

    fetchPostsPerDay();
  }, []);

  return (
    <Wrapper>
      <div id="bar-chart">
        {postsPerDay.map((item, index) => {
          const maxCount = Math.max(...postsPerDay.map(i => i.count));
          const barHeight = (item.count / maxCount) * 100;

          return (
            <div key={index} className="bar" style={{ height: `${barHeight}%`}}>
              {item.count}
            </div>
          );
        })}
      </div>
      <h3 style={{ textAlign: 'center' }}>number of posts per day</h3>
    </Wrapper>
  );
}

export default Info;
