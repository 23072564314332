import { HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home.js';
import Register from './pages/Register.js';
import AddPost from './pages/AddPost.js';
import Buy from './pages/Buy.js';
import Info from './pages/Info.js';
import Leaderboard from './pages/leaderboard.js';
import About from './pages/About.js';
import Search from './pages/Search.js';
import SinglePost from './pages/SinglePost.js';
import Reply from './pages/Reply.js';
import User from './pages/User.js';

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="post" element={<SinglePost />} />
          <Route path="register" element={<Register />} />
          <Route path="add" element={<AddPost />} />
          <Route path="buy" element={<Buy />} />
          <Route path="info" element={<Info />} />
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="about" element={<About />} />
          <Route path="search" element={<Search />} />
          <Route path="user" element={<User />} />
          <Route path="reply" element={<Reply />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
