import axios from "axios";

const api_ = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
});

const api_turbo = axios.create({
    baseURL: process.env.REACT_APP_TURBO_URL,
    headers: {
      'Content-Type': 'application/json',
    },
});

const api_mempool = axios.create({
    baseURL: process.env.REACT_APP_MEMPOOL_URL,
    headers: {
      'Content-Type': 'application/json',
    },
});

const api_inscribe = axios.create({
    baseURL: process.env.REACT_APP_INSCRIBE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
});

export const apiData = {api_, api_turbo, api_mempool, api_inscribe}