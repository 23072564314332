import React, { useEffect, useState } from 'react'
import Wrapper from '../components/Wrapper.js'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { services } from '../sevices/service.js';
import { serviceUtils } from '../sevices/service-utils.js';

function SinglePost() {
  const [post, setPost] = useState(null)
  const [replies, setReplies] = useState(null)
  const location = useLocation();
  const navigate = useNavigate()
  const query = new URLSearchParams(location.search).get('id');
  useEffect(() => {
    services.getSinglePost(query).then((response) => {
      setPost(response.documents[0])
    });
  }, [query])
  useEffect(() => {
    services.getSinglePostReplies(query).then((response) => {
      setReplies(response.documents[0])
    });
  }, [query])

  const handleReplyClick = (e, replytoID) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/reply?replytoID=${replytoID}`);
  };
  return (
    <Wrapper>
      <div id="singlePostContainer">
        {post && <div className="post">
          <p className="content">
            {post.content.split(/(https?:\/\/\S+)/g).map((part, index) =>
              part.match(/https?:\/\/\S+/) ? (
                <Link key={index} to={part} target="_blank" rel="noopener noreferrer">
                  {part}
                </Link>
              ) : (
                part
              )
            )}
          </p>
          <p>
            {post.replytoID ? (
              <>
                <Link to={`/user?user=${encodeURIComponent(serviceUtils.formatUsername(post.name))}`}>{post.name}</Link> replying to{' '}
                <Link to={`/post?id=${post.replytoID}`}>{post.replytoID.slice(0, 8)}...</Link> at{' '}
                {new Date(post.timestamp * 1000).toLocaleString()}
                <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
                  +reply
                </button>
              </>
            ) : (
              <>
                posted by <Link to={`/user?user=${encodeURIComponent(serviceUtils.formatUsername(post.name))}`}>{post.name}</Link> at{' '}
                {new Date(post.timestamp * 1000).toLocaleString()}
                <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
                  +reply
                </button>
              </>
            )}
          </p>
        </div>}
      </div>
      <div id="repliesContainer">
        {replies && <div className="post">
          <p className="content">
            {replies.content.split(/(https?:\/\/\S+)/g).map((part, index) =>
              part.match(/https?:\/\/\S+/) ? (
                <Link key={index} to={part} target="_blank" rel="noopener noreferrer">
                  {part}
                </Link>
              ) : (
                part
              )
            )}
          </p>
          <p>
            {replies.replytoID ? (
              <>
                <Link to={`/user?user=${encodeURIComponent(serviceUtils.formatUsername(replies.name))}`}>{replies.name}</Link> replying to{' '}
                <Link to={`/replies?id=${replies.replytoID}`}>{replies.replytoID.slice(0, 8)}...</Link> at{' '}
                {new Date(replies.timestamp * 1000).toLocaleString()}
                <button className='link' onClick={(e) => handleReplyClick(e, replies.inscriptionID)}>
                  +reply
                </button>
              </>
            ) : (
              <>
                posted by <Link to={`/user?user=${encodeURIComponent(serviceUtils.formatUsername(post.name))}`}>{post.name}</Link> at{' '}
                {new Date(post.timestamp * 1000).toLocaleString()}
                <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
                  +reply
                </button>
              </>
            )}
          </p>
        </div>}
      </div>
    </Wrapper>
  )
}

export default SinglePost