import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { services } from '../sevices/service.js'
import Wallet from 'sats-connect';
import { AddressPurpose } from "sats-connect";
import PopupData from './PopupData.js'

function Header() {
  const searchType = ['users', 'posts']
  const [searchValue, setSearchValue] = useState(null)
  const [searchSelect, setSearchSelect] = useState(searchType[0])
  const navigate = useNavigate()
  const [connected, setConnected] = useState(false);
  const [sortAddress, setsortAddress] = useState('');
  const [showDisconnect, setShowDisconnect] = useState(false);
  const disconnectRef = useRef(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState(null);
  const [showchangename, setshowchangename] = useState(false)




  useEffect(() => {
    if (window.localStorage.getItem('walletStatus')) {
      let ordinalAddress = window.localStorage.getItem('ordinalAddress')
      setConnected(true)
      const truncatedText = truncateString(ordinalAddress);
      setsortAddress(truncatedText)
      if (window.localStorage.getItem('selectedUsername')) {
        let name = JSON.parse(window.localStorage.getItem('selectedUsername'))
        setsortAddress(name.name_escaped)
        setshowchangename(true)
        setshowchangename(true)
      }
    }
  }, []);
  const handleSearch = async () => {
    if (searchValue) {
      if (searchSelect === 'users') {
        // First, try searching for a user
        const userData = await services.searchUsers(searchValue) // use encodeURIComponent to encode the query in the URL
        if (userData && userData.name && userData.name !== "AxiosError") {
          const userSansBitter = userData.name.slice(0, -7);  // remove last 7 characters ".bitter"
          navigate(`/user?user=${encodeURIComponent(userSansBitter)}`) // use encodeURIComponent to encode the username in the URL
          return;
        }
      } else if (searchSelect === 'posts') {
        // Try a text content search
        const contentData = await services.searchPosts(searchValue) // use encodeURIComponent to encode the query in the URL
        // If content was found, redirect to a search results page
        if (contentData && contentData.documents && contentData.documents.length > 0) {
          // Redirect to a search results page (you'll need to create this)
          navigate(`/search?query=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
          return;
        } else {
          // If no content was found, redirect to the add post page with the query parameter
          navigate(`/add?query=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
          return;
        }
      }
      // If no user or post is found, redirect to register page
      navigate(`/register?name=${encodeURIComponent(searchValue)}`) // use encodeURIComponent to encode the query in the URL
    }
  }
  const getNameByAddress = async (address) => {
    const names = await services.getNameByAddress(address)
    return names
  }
  const connectwallet = async () => {
    const response = await Wallet.request('getAccounts', {
      purposes: [AddressPurpose.Payment, AddressPurpose.Ordinals],
      message: 'Bitter.lol needs your BTC addresses!',
      network: { type: process.env.REACT_APP_BTCNETWORK }
    });
    const accounts = response;
    console.log(accounts)
    if (accounts.result) {
      window.localStorage.setItem('walletStatus', true)
      window.localStorage.setItem('paymentAddress', accounts.result[0].address)
      window.localStorage.setItem('ordinalAddress', accounts.result[1].address)
      window.localStorage.setItem('paymentPubKey', accounts.result[0].publicKey)
      window.localStorage.setItem('ordinalPubKey', accounts.result[1].publicKey)
      setConnected(true)
      const truncatedText = truncateString(accounts.result[1].address);
      setsortAddress(truncatedText)
      getnameByAddress(accounts.result[1].address)
    }
    else {
      setConnected(false)
    }

  }
  const getnameByAddress = async () => {
    let ordinalAddress = window.localStorage.getItem('ordinalAddress')
    const namesbyaddress = await getNameByAddress(ordinalAddress)
    if (namesbyaddress && namesbyaddress.count) {
      //localStorage.setItem('namesByAddress', namesbyaddress)
      setIsPopupOpen(true);
      setData(namesbyaddress.names)
    } else if (!namesbyaddress.count) {
      navigate('/register')
    }
  }
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    if (window.localStorage.getItem('selectedUsername')) {
      let name = JSON.parse(window.localStorage.getItem('selectedUsername'))
      setsortAddress(name.name_escaped)
    }
  };
  const disconnectwallet = async () => {
    await Wallet.disconnect();
    window.localStorage.removeItem('walletStatus')
    window.localStorage.removeItem('ordinalAddress')
    window.localStorage.removeItem('paymentAddress')
    window.localStorage.removeItem('paymentPubKey')
    window.localStorage.removeItem('ordinalPubKey')
    window.localStorage.removeItem('selectedUsername')
    setConnected(false)
    setShowDisconnect(false);
  }
  const truncateString = (str) => {
    const prefixLength = 4;
    const suffixLength = 4;
    const ellipsis = '...';
    if (str) {

      if (str.length <= prefixLength + suffixLength) {
        return str;
      }


      const start = str.slice(0, prefixLength);
      const end = str.slice(-suffixLength);
      return `${start}${ellipsis}${end}`;
    }
  }
  const handleClickOutside = (event) => {
    if (disconnectRef.current && !disconnectRef.current.contains(event.target)) {
      setShowDisconnect(false);
    }
  };
  useEffect(() => {
    if (showDisconnect) {
      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showDisconnect]);

  const showdisconnectbtn = (event) => {
    event.preventDefault();
    setShowDisconnect(!showDisconnect);
  };

  return (
    <>
      <nav>
        <ul id="navbar">
          <li><Link to="/">home</Link></li>
          <li><Link to="/register">register name</Link></li>
          <li><Link to="/add">create post</Link></li>
          <li><Link to="/buy">shop names</Link></li>
          <li><Link to="/info">stats</Link></li>
          <li><Link to="/leaderboard">leaderboards</Link></li>
          <li><Link to="/about">about</Link></li>
          {connected == false ?
            <>
              <li className='connectbtn' ref={disconnectRef}>
                <Link to="#" onClick={connectwallet}>
                  Connect
                </Link>
              </li>
            </>
            :
            <>
              <li className='connectbtn' ref={disconnectRef}>
                <Link to="#" onClick={showdisconnectbtn}>
                  {sortAddress}
                </Link>
                {showDisconnect && (
                  <div>
                    {showchangename &&
                      <div className='disconnect changenamebtn' onClick={getnameByAddress}>
                        Change name
                      </div>
                    }
                    <div className='disconnect' onClick={disconnectwallet} style={{ top: showchangename ? 65 : 30 }}>
                      Disconnect
                    </div>
                  </div>
                )
                }
              </li>
            </>
          }
        </ul>
      </nav>
      <header className="header">
        <h1>😖 bitter.lol</h1>
        <div className="search-container">
          <input type="text" id="search" placeholder="search" onChange={(e) => setSearchValue(e.target.value)} />
          <select id="search-type" onChange={(e) => setSearchSelect(e.target.value)}>
            <option value="users">users</option>
            <option value="posts">posts</option>
          </select>
          <button id="search-btn" onClick={handleSearch}>search</button>
        </div>
      </header>
      {data && <PopupData open={isPopupOpen} handleClose={handleClosePopup} data={data} />}
    </>
  )
}

export default Header