import React, { useEffect, useState } from 'react'
import Wrapper from '../components/Wrapper.js'
import { useLocation } from 'react-router-dom';
import { services } from '../sevices/service.js';
import Post from './home-post.js';

function User() {
  const [posts, setPosts] = useState(null)
  const [userExist, setUserExist] = useState(null)
  const [userName, setUserName] = useState(null)
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('user').toLowerCase();
  useEffect(() => {
    services.searchUsers(query).then((response) => {
      if (!response || !response.name) {
        setUserExist(false)
      } else {
        setUserExist(true)
        setUserName(response.name)
        const userSansBitter = response.name.slice(0, -7);  // remove last 7 characters ".bitter"
        services.getUserPosts(userSansBitter).then((response) => {
          setPosts(response.documents)
        })
      }
    });
  }, [query])
  return (
    <Wrapper>
      <div id="userPostContainer">
        {userName ? <h1>{userName}</h1> : null}
        {!userExist ? 'Invalid username' : !posts || posts.length === 0 ? <p className='no-posts-message'>This user has not yet posted</p> : posts.map((post, index) => (
          <Post key={index} post={post} redirectURL={`/post?id=${post.inscriptionID}`} />
        ))
        }
      </div>
    </Wrapper>
  )
}

export default User