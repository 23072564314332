import React, { useState, useEffect, useCallback, useRef } from 'react';
import Wrapper from '../components/Wrapper.js';
import '../css/buy.css';
import { services } from '../sevices/service.js';

function Buy() {
  const [allContent, setAllContent] = useState([]); // Holds all the data
  const [visibleContent, setVisibleContent] = useState([]); // Holds the data to display
  const [loading, setLoading] = useState(true);
  const [isLowToHigh, setIsLowToHigh] = useState(true);
  const observerRef = useRef();
  const ITEMS_PER_PAGE = 15;

  const populateContent = useCallback(async () => {
    setLoading(true);
    try {
      const data = await services.populateTurboContent();

      // Sort the data
      const sortedData = data.sort((a, b) =>
        isLowToHigh ? a.satoshi_price - b.satoshi_price : b.satoshi_price - a.satoshi_price
      );

      // Fetch additional content data
      const contentWithDetails = await Promise.all(
        sortedData.map(async (item) => {
          const contentData = await services.getTurboContent(item.inscription_id);
          return {
            ...item,
            content: contentData,
          };
        })
      );

      setAllContent(contentWithDetails); // Store all data
      setVisibleContent(contentWithDetails.slice(0, ITEMS_PER_PAGE)); // Initially display first 15 items
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [isLowToHigh]);

  useEffect(() => {
    populateContent();
  }, [populateContent]);

  useEffect(() => {
    const loadMoreItems = () => {
      const nextItems = allContent.slice(visibleContent.length, visibleContent.length + ITEMS_PER_PAGE);
      setVisibleContent(prevContent => [...prevContent, ...nextItems]);
    }
    const obsRef = observerRef.current
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && visibleContent.length < allContent.length) {
          loadMoreItems(); // Load more items when scroll reaches the bottom
        }
      },
      { threshold: 1 }
    );

    if (obsRef) {
      observer.observe(obsRef);
    }

    return () => {
      if (obsRef) {
        observer.unobserve(obsRef);
      }
    };
  }, [visibleContent, allContent]);

  const toggleSort = () => {
    setIsLowToHigh(!isLowToHigh);
  };

  return (
    <Wrapper>
      {/* <div id="header">
        <button onClick={toggleSort}>
          {isLowToHigh ? 'Sort High to Low' : 'Sort Low to High'}
        </button>
      </div> */}
      {loading && visibleContent.length === 0 ? (
        <div id="loading">Loading...</div>
      ) : (
        <div id="content">
          {visibleContent.map((item, index) => (
            <a
              key={index}
              className="item"
              href={`https://ordinalswallet.com/inscription/${item.inscription_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>{typeof item.content === 'string' ? item.content : item.content.name}</h3>
              <p>Price: {(item.satoshi_price * 0.00000001).toFixed(8)} BTC</p>
            </a>
          ))}
          <div ref={observerRef} id="scroll-observer"></div>
        </div>
      )}
      {loading && visibleContent.length > 0 && <div id="loading">Loading more...</div>}
      <div id="footer"></div>
    </Wrapper>
  );
}

export default Buy;
