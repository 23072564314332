import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {serviceUtils} from '../sevices/service-utils.js'

function Post({ post, redirectURL }) {
  const navigate = useNavigate();
  const formattedUsername = encodeURIComponent(serviceUtils.formatUsername(post.name));

  const handleReplyClick = (e, replytoID) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/reply?replytoID=${replytoID}`);
  };

  const handlePostClick = (e) => {
    if (redirectURL) {
      if (e.ctrlKey || e.metaKey || e.button === 1) {
        window.open(redirectURL, '_blank').focus();
      } else {
        navigate(redirectURL);
      }
    }
  };

  return (
    <div className="post" onClick={handlePostClick}>
      <p className="content">
        {post.content.split(/(https?:\/\/\S+)/g).map((part, index) =>
          part.match(/https?:\/\/\S+/) ? (
            <Link key={index} to={part} target="_blank" rel="noopener noreferrer">
              {part}
            </Link>
          ) : (
            part
          )
        )}
      </p>
      <p>
        {post.replytoID ? (
          <>
            <Link to={`/user?user=${formattedUsername}`}>{post.name}</Link> replying to{' '}
            <Link to={`/post?id=${post.replytoID}`}>{post.replytoID.slice(0, 8)}...</Link> at{' '}
            {new Date(post.timestamp * 1000).toLocaleString()}
            <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
              +reply
            </button>
          </>
        ) : (
          <>
            posted by <Link to={`/user?user=${formattedUsername}`}>{post.name}</Link> at{' '}
            {new Date(post.timestamp * 1000).toLocaleString()}
            <button className='link' onClick={(e) => handleReplyClick(e, post.inscriptionID)}>
              +reply
            </button>
          </>
        )}
      </p>
    </div>
  );
}

export default Post;
