import { Tap } from '@cmdcode/bton'
import { apiData } from './api.js';

const inscribe = async (body) => {
    return await apiData.api_inscribe.post(`/inscribe`, JSON.stringify(body))
        .then(response => {
            return response
        })
        /*.then(data => {
            callback(data);
        })*/
        .catch((error) => {
            return error;
        });
};

const inscribeChild = async (body) => {
    return await apiData.api_inscribe.post(`/inscribechild`, JSON.stringify(body))
        .then(response => {
            return response
        })
        /*.then(data => {
            callback(data);
        })*/
        .catch((error) => {
            return error;
        });
};

const checkOrderStatus = async (orderID) => {

    return await apiData.api_inscribe.get(`/orderstatus?orderID=${orderID}`)
        .then(response => {
            return response
        })
        /*.then(data => {
            if (data.status.toLowerCase().includes("done")) {
                callback({
                    status: data.status,
                    inscriptionID: data.inscriptionIDs[0],
                });
            } else {
                callback({
                    status: data.status,
                });
            }
        })*/
        .catch((error) => {
            console.error('Error:', error);
        });
};

const isValidTaprootAddress = (address) => {
    try {
        const decodedAddress = Tap.decodeAddress(address);
        return Boolean(decodedAddress.hex);
    } catch (e) {
        // console.error('Invalid Taproot Address:', e);
    }
    return false;
};

const getRecommendedFeeRate = async (callback) => {
    try {
        const response = await apiData.api_mempool.get(`/fees/recommended`);
        if (response) {
            callback(response.data.fastestFee)
        }
    } catch (error) {
        return [];
    }
};

const getFees = (callback) => {
    fetch('https://mempool.space/api/v1/fees/recommended')
        .then(response => response.json())
        .then(data => {
            callback(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

const checkNames = async (name, suffix, callback) => {
    const combinedName = name + suffix;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer fa59fb26361ab4e4958d3cc673df09f837f95adbe5e4cd10b605b0ce68da9fcf");

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };

    const unisatEndpoint = 'https://web3.inscribe.today/https://unisat.io/open-api-v1/indexer/inscriptions/category/text/search?name=' + combinedName;
    const ordbotEndpoint = 'https://web3.inscribe.today/https://api2.ordinalsbot.com/search?text=' + combinedName;

    let inscriptionIDs = [];

    try {
        const unisatResponse = await fetch(unisatEndpoint, requestOptions);
        if (!unisatResponse.ok) {
            throw new Error('Network response from unisat API was not ok');
        }
        const unisatData = await unisatResponse.json();
        if (unisatData.data.matchCount > 0) {
            unisatData.data.detail.forEach(result => {
                inscriptionIDs.push({
                    id: result.inscriptionId,
                    name: result.inscriptionName,
                    timestamp: result.timestamp,
                });
            });
        }

        callback("unisat", unisatData.data.matchCount, inscriptionIDs);
    } catch (error) {
        console.error('Error:', error);

        try {
            const ordbotResponse = await fetch(ordbotEndpoint);
            if (!ordbotResponse.ok) {
                throw new Error('Network response from ordbot API was not ok');
            }
            const ordbotData = await ordbotResponse.json();
            if (ordbotData.count > 0) {
                ordbotData.results.forEach(result => {
                    const str = result.createdat;
                    const date = new Date(Date.parse(str));
                    const unixTimestamp = date.getTime() / 1000; // because getTime() returns milliseconds

                    inscriptionIDs.push({
                        id: result.inscriptionid,
                        name: result.contentstr,
                        timestamp: unixTimestamp,
                    });
                });
            }

            callback("ordbot", ordbotData.count, inscriptionIDs);
        } catch (error) {
            console.error('Error:', error);
            callback("none", 0, []);
        }
    }
};

const startCountdown = (milliseconds, setCountdown) => {
    let seconds = Math.floor(milliseconds / 1000); // convert milliseconds to seconds

    const countdownTimer = setInterval(() => {
        seconds--;
        if (seconds <= 0) {
            clearInterval(countdownTimer);
            setCountdown("Time's up!");
        } else {
            setCountdown(`${seconds} seconds remaining`);
        }
    }, 1000);
};

export const inscriboorService = { inscribe, inscribeChild, checkOrderStatus, isValidTaprootAddress, getRecommendedFeeRate, getFees, checkNames, startCountdown }